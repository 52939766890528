.input-card {
    padding: tr(40);
    border-radius: $rounded-small;
    background-color: white;
    // border: tr(1) solid $color-gray-light;
    border-radius: $rounded-small;
    box-shadow: $shadow-gray;
    
    &--style2 {
        border: unset;
        padding: tr(64) tr(44);
        border-radius: $rounded-large;

        
    }

    @media (max-width: 575.98px) {
        padding: tr(24) tr(16);
    }

    &__main-img {
        height: tr(22);
        margin-bottom: tr(20);
    }

    &__program {
        display: flex;
        // align-items: center;
        margin-bottom: tr(24);

        @media (max-width: 767.98px) {
            &._thumb {
                display: block;
            }
        }

        img {
            // height: tr(112);
            width: tr(123);
            // width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: $rounded-small;
        }

        ._back-anchor {
            margin-right: tr(8);
            margin-top: tr(3);
            
            a {
                transition: all .2s ease-in-out;

                i {
                    font-size: tr(22);
                    color: $color-base;
                }

                &:hover {
                    opacity: .8;
                }
            }
        }

        &__info {
            flex: 1;
            margin-left: tr(16);
            // margin-top: tr(16);

            ._title {
                font-family: $font-family2;
                font-size: tr(24);
                line-height: 1.3;
                font-weight: $fw-bold;
                margin-bottom: tr(8);
            }

            ._target {
                display: flex;

                > * {

                    span {
                        color: $color-base2;
                        margin-bottom: tr(4);
                        display: block;
                    }

                    p {
                        font-size: tr(16);
                    }
                }

                ._fund {

                    p {
                        font-weight: $fw-bold;
                        color: $color-accent;
                    }
                }

                ._time {
                    flex: 1;
                    text-align: right;

                    p {
                        color: $color-base;
                        font-weight: $fw-bold;
                    }
                }
            }
        }

        @media (max-width:767.98px) {
            // display: block;

            img {
                width: 100%;
                // height: tr(240);
                aspect-ratio: 1/1;
                margin-bottom: tr(16);
            }

            &__info {
                margin-left: unset;
                // margin-top: unset;
            }
        }

        // @media (max-width:767.98px) {

        //     img {
        //         height: tr(160);
        //     }
        // }
    }

    .sc-header {
        
        h1 {
            display: flex;

            ._back-anchor {
                margin-right: tr(16);
                font-size: tr(24);
                
                a {
                    color: $color-base;
                }
            }

            @media (min-width:576px) {
                line-height: tr(64);
            }
        }
    }

    // &__title {
    //     color: $color-primary;
    //     margin-bottom: tr(24);
    // }

    &__subtitle {
        font-size: tr(16);
        line-height: tr(24);
        display: block;
        color: $color-base2;
        margin-bottom: tr(24);
    }

    &__normsub {
        display: block;
        margin-bottom: tr(8);
        font-size: tr(16);
        font-weight: $fw-bold;
    }

    &__smallsub {
        display: block;
        color: $color-base2;
        margin-bottom: tr(8);
    }

    &__divsub {
        margin-bottom: tr(16);
    }

    &__total {
        font-size: tr(24);
        color: $color-base2;
        font-weight: $fw-bold;
    }

    &__small {
        font-size: tr(12);
        margin-bottom: tr(24);
    }

    ._error {
        font-size: tr(12);
        color: $color-error;
        margin-top: tr(8);
    }

    &__label {
        font-size: tr(16);

        a {
            color: $color-accent;
            text-decoration: none;
        }
    }

    .cb,
    .rb {
        margin-bottom: tr(32);
    }

    .tab-wrapper {
        margin: 0 tr(-40) tr(24);
    }

    #regular-target {
        display: none;
    }
}

.fundraiser {
    background-image: url('../img/pattern-islamic.svg');
    max-width: tr(808);
    margin: 0 auto;

    
    &__inner {
        max-width: tr(492);
        margin-left: auto;
        margin-right: auto;
        padding: tr(24) 0;
    }

    .pattern-icon {
        width: tr(167);
        height: tr(167);

        i {
            font-size: tr(100);
        }

        @media (max-width: 991.98px) {
            width: tr(88);
            height: tr(88);

            i {
                font-size: tr(48);
            }
        }
    }

    #link {
        display: block;
        background-color: $color-gray-light;
        font-size: tr(18);
        padding: tr(12) tr(18);
        border-radius: $rounded-small;
        margin-bottom: tr(16);
        word-break: break-all;
    }
}