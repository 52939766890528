.sc-terms {
    @extend .sc-bg;
    padding: 0 0 tr(80);
    // padding: 0 0 tr(64);

    // @media (max-width:991.98px) {
    //     padding: 0 0 tr(48);
    // }
    
    @media (max-width:767.98px) {
        padding: 0 0 tr(40);
    }
    
    // @media (max-width:575.98px) {
    //     padding: 0 0 tr(24);
    // }
    
    .main-content {
        margin-top: tr(-8);
        .box {
            min-height: tr(400);

            &-side {
                .input-wrap {
                    margin-bottom: tr(16);
                }

                &__wrapper {

                    ul {

                        li {

                            button {
                                width: 100%;
                                font-family: $font-family2;
                                font-size: tr(18);
                                display: block;
                                color: $color-base2;
                                padding: tr(12) tr(24) tr(12) tr(24+3);
                                border-radius: $rounded-small;
                                position: relative;
                                transition: all .2s ease-out;

                                &::before {
                                    display: block;
                                    position: absolute;
                                    content: '';
                                    top: 0;
                                    left: 0;
                                    width: tr(3);
                                    height: 100%;
                                    border-radius: $rounded-small;
                                    background-color: $color-accent;
                                    visibility: hidden;
                                }

                                &:hover {
                                    background-color: mix($color-accent, white, 20%) !important;
                                }
                                
                                &.active {
                                    color: $color-accent;
                                    font-weight: $fw-bold;
                                    background-color: transparent;
                                    
                                    &::before {
                                        visibility: visible;
                                    }
                                }
                            }

                            // &._active {

                            //     button {
                                    
                            //     }
                            // }
                        }
                    }
                }

                @media (max-width: 991.98px) {
                    margin-bottom: tr(30);
                }
            }
        }

    }

    .cta-card {
        margin: tr(80) 0;

        @media (max-width:991.98px) {
            margin: tr(48) 0;
        }
        
        @media (max-width:767.98px) {
            margin: tr(32) 0;
        }
        
        @media (max-width:575.98px) {
            margin: tr(24) 0;
        }
    }

    .list-card {
        @media (min-width: 992px) {
            grid-template-columns: repeat(20, 1fr);
        }

        @media (max-width: 767.98px) {
            gap: tr(16);
        }
        &__item {
            padding: tr(16);
            border: tr(1) solid $color-gray-lightest;
            border-radius: $rounded-small;
            box-shadow: $shadow-blue;
            text-align: center;

            @media (max-width: 767.98px) {
                padding: tr(8);
            }

            > div {
                border-radius: $rounded-circle;
                height: tr(72);
                width: tr(72);
                background-color: $color-accent;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: tr(16);
                color: white;

                i {
                    font-size: tr(40);
                }

                @media (max-width: 767.98px) {
                    height: tr(56);
                    width: tr(56);
                    margin-bottom: tr(8);

                    i {
                        font-size: tr(32);
                    }
                }
            }
            p {
                font-size: tr(18);
                margin-bottom: tr(8);
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;

                @media (max-width: 767.98px) {
                    font-size: tr(14);
                }
            }
            .h5 {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                line-height: 1;

                @media (max-width: 767.98px) {
                    font-size: tr(16);
                }
            }
        }
    }

    .report-table {
        padding-top: tr(80);

        .h3 {
            margin-bottom: tr(40);

            @media (max-width: 767.98px) {
                margin-bottom: tr(24);
            }
        }

        table {
            width: 100%;

            thead {

                tr {
                    th {
                        height: tr(56);
                        vertical-align: top;

                        &:first-child,
                        &:last-child {
                            width: tr(80);
                            text-align: center;
                        }
                    }
                }

                @media (max-width: 991.98px) {
                    display: none;
                }
            }

            tbody {
                color: $color-base2;
                
                tr {
                    border-bottom: tr(1) solid $color-gray;
                    font-size: tr(18);

                    td {
                        padding: 0 tr(8);
                        height: tr(70);

                        &:first-child,
                        &:last-child {
                            text-align: center;
                        }

                        ._download {

                            i {
                                font-size: tr(32);
                            }
                        }

                        @media (max-width: 991.98px) {
                            &:first-child {
                                display: none;
                            }
                        }
                    }

                    @media (max-width: 991.98px) {
                        display: flex;
                        flex-direction: column;
                        border: tr(1) solid $color-gray;
                        border-radius: $rounded-small;
                        box-shadow: $shadow-blue;
                        padding: tr(16);
                        position: relative;
                        
                        &:not(:last-child) {
                            margin-bottom: tr(24);
                        }

                        td {
                            height: auto;

                            &:nth-child(2) {
                                font-weight: $fw-bold;
                                color: $color-base;
                                padding-bottom: tr(16);
                            }

                            &:nth-child(3) {
                                font-size: tr(14);
                            }

                            &:last-child {
                                height: tr(32);
                                position: absolute;
                                right: tr(16);
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }

                    @media (max-width: 767.98px) {
                        padding: tr(8);
                        
                        &:not(:last-child) {
                            margin-bottom: tr(16);
                        }

                        td {

                            &:nth-child(2) {
                                font-size: tr(14);
                                padding-bottom: tr(8);
                                width: calc(100% - tr(40));
                            }

                            &:nth-child(3) {
                                font-size: tr(12);
                            }

                            &:last-child {
                                right: tr(8);
                                height: tr(24);

                                ._download {
                                    i {
                                        font-size: tr(24);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}