@import url($font-source);

::selection {
    background-color: $color-accent;
    color: white;
}

html {
    font-size: $font-base;
}

body {
    font-family: $font-family;
    font-size: $font-body;
    font-weight: $fw-normal;
    line-height: $lh-base;
    color: $color-base;
    background-color: $color-bg;
}

main {
    overflow: hidden;
    margin-top: $nav-height;
    // overflow-x: hidden;
    min-height: calc(100vh - tr(424));
    transition: all .2s ease-in-out;

    @media (max-width: 991.98px) {
        margin-top: $nav-height-sm;
        min-height: calc(100vh - tr(116));
    }
}

button {
    padding: 0;
    background-color: white;
}

label {
    margin: 0;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: $color-accent;
    text-decoration: none;

    &:hover {
        color: mix($color-accent, white, 80%);
        text-decoration: none;
    }
}

p {
    margin-bottom: 0;
}

ol {

    &.ls-loro {
        list-style-type:lower-roman;
    }
    &.ls-init {
        list-style-type:initial;
    }
}

%container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container {

    &-sm {
        @extend %container;
    }

    &-xs {
        @extend %container;
    }

    @media (min-width:1284px) {
        max-width: 1224px;
    }
}


@media (min-width: 576px) {
    .container {
    
        &-sm {
            max-width: 540px;
        }
    
        &-xs {
            max-width: 540px;
        }
    }
}
  
@media (min-width: 768px) {
    .container {
        
        &-sm {
            max-width: 720px;
        }
    
        &-xs {
            max-width: 720px;
        }
    }
}
  
@media (min-width: 992px) {
    .container {
      
        &-sm {
            max-width: tr(736);
        }
    
        &-xs {
            max-width: tr(544);
        }
    }
}
  
@media (min-width: 1200px) {
    .container {
        
        &-sm {
            max-width: tr(736);
        }
    
        &-xs {
            max-width: tr(544);
        }
    }
}

article {
    // opacity: .8;
    color: mix($color-base, white, 80%);
    line-height: 1.7;
    justify-content: space-between;

    > * {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    ._sc {
        margin-bottom: tr(56);
    }

    ._title {
        font-size: tr(18);
        font-weight: $fw-bold;
        margin-bottom: tr(16);
    }

    h5 {
        margin-bottom: tr(24);

        span {
            padding-right: tr(8);
        }
    }

    p {
        font-size: tr(16);
        margin-bottom: tr(32);
        color: $color-base2;
    }

    img {
        margin-bottom: tr(32);
        width: 100%;
        max-width: 100% !important;
    }

    ol {
        padding: 0 0 0 tr(16);

        li {
            margin-bottom: tr(24);
            padding-left: tr(8);
            list-style: decimal;
        }
    }
}
// modal


.modal-dialog {
    margin: tr(12) tr(12);

    @media (min-width: 576px) {
        margin: tr(12) auto;
        max-width: 540px !important;
    }

    @media (min-width: 768px) {
        margin: tr(12) auto;
        max-width: 720px !important;
    }

    @media (min-width: 992px) {
        margin: tr(12) auto;
        max-width: 960px !important;
    }

    // @media (min-width: 1200px) {
    //     margin: 0 auto;
    //     max-width: 1140px !important;
    // }

    // @media (min-width: 1284px) {
    //     margin: 0 auto;
    //     max-width: 1248px !important;
    // }

    &--small {
        max-width: tr(440) !important;
    }

    .modal-content {
        padding: tr(24) !important;

        .modal-body {
            padding: 0;
        }

        h2 {
            margin-bottom: tr(24);
            line-height: 1.3;
        }

        ._icon {
            margin-bottom: tr(24);
        }

        ._paragh {
            font-size: tr(16);
            line-height: tr(24);
            color: $color-base2;
            width: 90%;
            margin: 0 auto tr(24);

            &._l {
                font-family: $font-family2;
                font-weight: $fw-bold;
                font-size: tr(18);
                color: $color-secondary;
            }
        }
    }
}

.pagination {

    .page-item {
        margin: 0 tr(4);

        .page-link {
            border: 0;
            // box-shadow: $shadow;
            border-radius: $rounded-small;
            width: tr(32);
            height: tr(32);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-accent;
            border: tr(1) solid $color-gray-light;
        }

        &.active {
            
            .page-link {
                background-color: $color-accent;
                color: white;
            }
        }
    }
}

.modal-zakat-calc,
.modal-pay-method {
    max-width: tr(704) !important;

    .modal-content {
        padding: 0 !important;
    }
}

.iframe-video {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: unset !important;
    border-radius: 8px;
}